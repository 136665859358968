<template>
  <div>
    <b-modal
        id="modal-delete-personalization-entry"
        ref="modal-delete-personalization-entry"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.deletePersonalizationEntry.title') }}</h3>
      </template>

      <template #default>
        <div class="delete-icon text-center">
          <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
          <div class="mt-2 mb-4">{{ $t('modals.deletePersonalizationEntry.message') }}</div>
        </div>
      </template>

      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            @click="deletePersonalizationEntry"
        >
          {{ $t('modals.deletePersonalizationEntry.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalDeletePersonalizationEntry',
  props: {
    idProp: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$refs['modal-delete-personalization-entry'].show();
  },
  methods: {
    deletePersonalizationEntry() {
      this.$emit('delete-contact', this.idProp);
      this.$refs['modal-delete-personalization-entry'].hide();
    },
    cancelModal() {
      this.$emit('close-delete-entry-modal');
      this.$refs['modal-delete-personalization-entry'].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
